



































































































































































import Vue from 'vue';
export default Vue.extend({
  name: 'ShareModal',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    shareURL: {
      type: String,
      required: true,
    },
    sessionId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      name: '',
    };
  },
  methods: {
    jumpToSession(): void {
      this.$router.push(`/presenter/sessions/${this.sessionId}/`);
    },
  },
});
